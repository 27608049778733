import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from '../services/login.service';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class RequestHeadersInterceptor implements HttpInterceptor {

  constructor(private authService: LoginService, private toastr: ToastrService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!this.authService.isTokenExpired()) {
      const token = JSON.parse(localStorage.getItem('user')!).access_token
      request = request.clone({
        headers: 
          request.headers
          .append('Accept', 'application/json')
          .append('Authorization', localStorage.getItem('user') ? `Bearer ${token}` : '')
          // .append('Content-Type', 'false')
          // .append('Service-Secret', 'service_001')
          // .append("mimeType", "multipart/form-data")
          // .append("contentType", 'false')
          // .append("processData", 'false')
          .append('Accept-Language', localStorage.getItem('currentLang') ? localStorage.getItem('currentLang')! : 'ar')
          // .append('Content-Type', 'multipart/form-data')
        },)
    } else {
      this.authService.Logout()
    }
    
    return next.handle(request);
  }
}
