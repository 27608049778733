<div class="login-card">
  <div>
    <div class="login-main">
      <div>
        <a class="logo" routerLink="/">
          <!-- <img class="img-fluid for-light" src="assets/images/logo/logo.png" alt="looginpage" /> -->
          <!-- <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="looginpage" /> -->
          <img class="img-fluid for-light" src="assets/images/logo/report_logo.svg" alt="looginpage" />
          <img class="img-fluid for-dark" src="assets/images/logo/report_logo.svg" alt="looginpage" />
        </a>
      </div>
      <form class="theme-form" [formGroup]="loginForm">
        <h4>{{ 'Sign In To Account' | translate}}</h4>
        <div class="d-flex ai-c flex-col login-gap">
          <div class="form-group">
            <!-- <label class="col-form-label">{{'Forms.Email' | translate}}</label> -->
            <input class="form-control" type="email" required="" placeholder="{{'Forms.Email Or Phone' | translate}}" name="user_name" formControlName="user_name" />
            <div *ngIf="loginForm.controls['user_name'].touched && loginForm.controls['user_name'].errors?.['required']" class="text text-danger mt-1">{{'Validations.Email Is Required' | translate}}</div>
            <div *ngIf="loginForm.controls['user_name'].touched && loginForm.controls['user_name'].errors?.['user_name']" class="text text-danger mt-1">{{'Validations.Invalid Email' | translate}}</div>
          </div>
          <div class="form-group">
            <!-- <label class="col-form-label">{{'Forms.Password' | translate}}</label> -->
            <input class="form-control" [type]="show ? 'text' : 'password'" name="password" formControlName="password" required="" placeholder="{{'Forms.Password' | translate}}" />
            <div class="show-hide" (click)="showPassword()" *ngIf="!show">
              <!-- <span class="show">{{'Forms.Show' | translate}}</span> -->
              <div class="img">
                <img src="assets/images/icons/show_pass.svg" alt="">
              </div>
            </div>
            <div class="show-hide" (click)="showPassword()" *ngIf="show">
              <!-- <span class="Hide">{{'Forms.Hide' | translate}}</span> -->
              <div class="img">
                <img src="assets/images/icons/hide_pass.svg" alt="">
              </div>
            </div>
            <div *ngIf="loginForm.controls['password'].touched && loginForm.controls['password'].errors?.['required']" class="text text-danger mt-1">{{'Validations.Password Is Required' | translate}}</div>
          </div>
          <div class="form-group mb-0">
            <div>
              <button class="transparent-button change-language" (click)="changeLanguage(translate.currentLang == 'ar' ? 'en' : 'ar');customizeLayoutType(translate.currentLang == 'ar' ? 'rtl' : 'ltr')">
                <!-- <img src="assets/svg/translate.svg" alt=""> -->
                <span class="language-icon">{{isDirRTL ? 'e' : 'a'}}</span>
                <span *ngIf="isDirRTL">الإنجليزية</span>
                <span *ngIf="!isDirRTL">Arabic</span>
              </button>
            </div>
            <a [routerLink]="'/auth/forgot-password'" class="link">{{'Forms.Forget Password' | translate}}</a>
            <div class="text-end login-button-box mt-3">
              <button class="login-button" (click)="login()" type="button"><span>{{'Forms.Sign In' | translate}}</span></button>
            </div>
          </div>
          <a [routerLink]="'/auth/login-as'" class="back">الرجوع لجميع الحسابات</a>
        </div>
      </form>
    </div>
  </div>
</div>
