<!-- Page Header Start-->
<div class="page-header" [class.close_icon]="navServices.collapseSidebar">
  <div class="header-wrapper row m-0">
    <app-search></app-search>
    <div class="header-logo-wrapper col-auto p-0 ai-c">
      <div class="toggle-sidebar" (click)="sidebarToggle()">
        <app-feather-icons [icon]="'align-center'" class="status_toggle middle"></app-feather-icons>
      </div>
      <div class="logo-wrapper">
        <a routerLink="/dashboard">
          <!-- <img class="img-fluid for-light" src="assets/images/logo/logo.png" alt="" />
          <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="" /> -->
          <ng-container *ngIf="isEnvironmentDevelopment">
            <img class="img-fluid for-light" src="assets/images/logo/logo_osj.svg" alt="" />
            <img class="img-fluid for-dark" src="assets/images/logo/logo_osj.svg" alt="" />
          </ng-container>
          <ng-container *ngIf="!isEnvironmentDevelopment">
            <div class="img">
              <img src="assets/images/logo/noblaa.png" alt="">
            </div>
          </ng-container>
        </a>
      </div>
    </div>
    <div class="left-header col-xxl-5 col-xl-6 col-lg-5 col-md-4 col-sm-3 p-0">
      <!-- <img class="img-fluid for-light" src="assets/images/logo/logo.png" alt="" />
      <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="" /> -->
      <!-- <img class="img-fluid for-light" src="assets/images/logo/logo_osj.svg" alt="" />
      <img class="img-fluid for-dark" src="assets/images/logo/logo_osj.svg" alt="" /> -->
      <ng-container *ngIf="isEnvironmentDevelopment">
        <img class="img-fluid for-light" src="assets/images/logo/logo_osj.svg" alt="" />
        <img class="img-fluid for-dark" src="assets/images/logo/logo_osj.svg" alt="" />
      </ng-container>
      <ng-container *ngIf="!isEnvironmentDevelopment">
        <div class="img">
          <img src="assets/images/logo/noblaa.png" alt="">
        </div>
      </ng-container>
    </div>
    <div class="nav-right col-xxl-7 col-xl-6 col-md-7 col-8 pull-right right-header p-0 ms-auto" *ngIf="!isDashboard">
      <ul class="nav-menus">
        <li class="toggle-sidebar d-none d-sm-block d-lg-none" (click)="sidebarToggle()">
          <app-feather-icons [icon]="'align-center'" class="status_toggle middle"></app-feather-icons>
        </li>
        <li class="language-nav" (click)="languageToggle()">
          <app-languages></app-languages>
        </li>
        <li>
          <span class="header-search" (click)="searchToggle()">
            <svg>
              <use href="assets/svg/icon-sprite.svg#search"></use>
            </svg>
          </span>
        </li>
        <li>
          <div class="mode" (click)="layoutToggle()">
            <svg>
              <use href="assets/svg/icon-sprite.svg#moon"></use>
            </svg>
          </div>
        </li>
        <li class="onhover-dropdown">
          <app-notification></app-notification>
        </li>
        <li class="profile-nav onhover-dropdown p-0">
          <app-my-account></app-my-account>
        </li>
      </ul>
    </div>
    <div class="nav-right col-xxl-7 col-xl-6 col-md-7 col-8 pull-right right-header p-0 ms-auto d-lg-none" *ngIf="isDashboard">
      <ul class="nav-menus">
        <li class="toggle-sidebar d-none d-sm-block d-lg-none" (click)="sidebarToggle()">
          <app-feather-icons [icon]="'align-center'" class="status_toggle middle"></app-feather-icons>
        </li>
        <li class="language-nav" (click)="languageToggle()">
          <app-languages></app-languages>
        </li>
        <li>
          <span class="header-search" (click)="searchToggle()">
            <svg>
              <use href="assets/svg/icon-sprite.svg#search"></use>
            </svg>
          </span>
        </li>
        <li>
          <div class="mode" (click)="layoutToggle()">
            <svg>
              <use href="assets/svg/icon-sprite.svg#moon"></use>
            </svg>
          </div>
        </li>
        <li class="onhover-dropdown">
          <app-notification></app-notification>
        </li>
        <li class="profile-nav onhover-dropdown p-0">
          <app-my-account></app-my-account>
        </li>
      </ul>
    </div>
    <div class="header d-none d-sm-flex ai-c" *ngIf="isDashboard"  [ngStyle]="{'padding': isEnvironmentDevelopment ? '20px 30px' : '10px 30px' }">
      <div class="logo-wrapper">
        <a routerLink="/dashboard">
          <!-- <img class="img-fluid for-light" src="assets/images/logo/logo.png" alt="" />
          <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="" /> -->
          <!-- <img class="img-fluid for-light" src="assets/images/logo/logo_osj.svg" alt="" />
          <img class="img-fluid for-dark" src="assets/images/logo/logo_osj.svg" alt="" /> -->

          <ng-container *ngIf="isEnvironmentDevelopment">
            <img class="img-fluid for-light" src="assets/images/logo/logo_osj.svg" alt="" />
            <img class="img-fluid for-dark" src="assets/images/logo/logo_osj.svg" alt="" />
          </ng-container>
          <ng-container *ngIf="!isEnvironmentDevelopment">
            <div class="img">
              <img src="assets/images/logo/noblaa.png" alt="">
            </div>
          </ng-container>
        </a>
      </div>
      <div class="nav-right col-xxl-7 col-xl-6 col-md-7 col-8 pull-right right-header p-0 ms-auto relative-box">
        <ul class="nav-menus">
          <li class="toggle-sidebar d-none d-sm-block d-lg-none" (click)="sidebarToggle()">
            <app-feather-icons [icon]="'align-center'" class="status_toggle middle"></app-feather-icons>
          </li>
          <li class="language-nav" (click)="languageToggle()">
            <app-languages></app-languages>
          </li>
          <li>
            <span class="header-search" (click)="searchToggle()">
              <svg>
                <use href="assets/svg/icon-sprite.svg#search"></use>
              </svg>
            </span>
          </li>
          <li>
            <div class="mode" (click)="layoutToggle()">
              <svg>
                <use href="assets/svg/icon-sprite.svg#moon"></use>
              </svg>
            </div>
          </li>
          <li class="onhover-dropdown">
            <app-notification></app-notification>
          </li>
          <li class="profile-nav onhover-dropdown p-0">
            <app-my-account></app-my-account>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<!-- Page Header Ends -->
