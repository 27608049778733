import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './auth/login/login.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// // for HttpClient import:
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
// // for Router import:
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
// // for Core import:
import { LoadingBarModule } from '@ngx-loading-bar/core';

import { CookieService } from 'ngx-cookie-service';

import { OverlayModule } from '@angular/cdk/overlay';
import { SharedModule } from './shared/shared.module';

import { StepsModule } from 'primeng/steps';

import { AuthGuard } from './shared/guards/auth.guard';
import { SetupComponent } from './components/setup/setup.component';
import { JwtModule, JwtModuleOptions } from '@auth0/angular-jwt';
import { SetupGuard } from './shared/guards/setup.guard';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { rolesGuard } from './shared/guards/roles.guard';
import { ForgetPasswordComponent } from './auth/forget-password/forget-password.component';
import { WhichUserLoginComponent } from './auth/which-user-login/which-user-login.component';
import { MapLoaderService } from './shared/services/map-loader.service';
// import { JwtHelperService, JwtModule } from '@auth0/angular-jwt';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const JWT_Module_Options: JwtModuleOptions = {
  config: {
    tokenGetter: JSON.parse(localStorage.getItem('user')!)?.access_token,
  },
};

export function tokenGetter() {
  return JSON.parse(localStorage.getItem('user')!)?.access_token;
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SetupComponent,
    ForgetPasswordComponent,
    WhichUserLoginComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    OverlayModule,
    SharedModule,
    HttpClientModule,
    NgbModule,
    StepsModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
      },
    }),
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
    }),
    TranslateModule.forRoot({
      defaultLanguage: 'ar',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    // for HttpClient use:
    LoadingBarHttpClientModule,
    // for Router use:
    LoadingBarRouterModule,
    // for Core use:
    LoadingBarModule,
  ],
  providers: [
    AuthGuard,
    SetupGuard,
    rolesGuard,
    CookieService,
    MapLoaderService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
