export const environment = {
  production: true,
  version: 'v1',
  // basicUrl: 'https://gateway.abnaey.com/api'
  basicUrl: 'https://gateway.nobala.edu.sa/api',
  //basicUrl: 'https://gateway-ajial.abnaey.com/api',
  parentUrl: 'https://parent.nobala.edu.sa/',
  //parentUrl: 'https://parent-ajial.abnaey.com/',
  dmUrl: 'https://dm.nobala.edu.sa/',
  development: false,
  mapsApiKey: 'AIzaSyDZsQEDaOUzZC69BShQQUTW_x_dVVHXO8Y',
};
