import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { ContentComponent } from './shared/components/layout/content/content.component';
import { content } from './shared/routes/routes';
import { FullComponent } from './shared/components/layout/full/full.component';
import { full } from './shared/routes/full.routes';
import { AuthGuard } from './shared/guards/auth.guard';
import { SetupGuard } from './shared/guards/setup.guard';
import { ForgetPasswordComponent } from './auth/forget-password/forget-password.component';
import { WhichUserLoginComponent } from './auth/which-user-login/which-user-login.component';

const routes: Routes = [
  // {
  //   path: '',
  //   redirectTo: 'dashboard/default',
  //   pathMatch: 'full'
  // },
  {
    path: 'auth/login-as',
    component: WhichUserLoginComponent
  },
  {
    path: 'auth/login',
    component: LoginComponent
  },
  {
    path: 'auth/forgot-password',
    component: ForgetPasswordComponent
  },
  {
    path: "setup",
    canActivate: [AuthGuard, SetupGuard],
    loadChildren: () => import("./components/setup/setup.module").then((m) => m.SetupModule),
  },
  {
    path: '',
    component: ContentComponent,
    canActivate: [AuthGuard],
    children: content
  },
  {
    path: '',
    component: FullComponent,
    // canActivate: [AuthGuard],
    children: full
  },
  {
    path: '**',
    redirectTo: 'dashboard'
  }
];

@NgModule({
  imports: [
    [RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled', })
    ]
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
